export const tabell = [
  {
    bruto: "8 100",
    taxCut: 0,
  },
  {
    bruto: "8 200",
    taxCut: 8,
  },
  {
    bruto: "8 300",
    taxCut: 37,
  },
  {
    bruto: "8 400",
    taxCut: 66,
  },
  {
    bruto: "8 500",
    taxCut: 95,
  },
  {
    bruto: "8 600",
    taxCut: 124,
  },
  {
    bruto: "8 700",
    taxCut: 152,
  },
  {
    bruto: "8 800",
    taxCut: 181,
  },
  {
    bruto: "8 900",
    taxCut: 210,
  },
  {
    bruto: "9 000",
    taxCut: 239,
  },
  {
    bruto: "9 100",
    taxCut: 268,
  },
  {
    bruto: "9 200",
    taxCut: 297,
  },
  {
    bruto: "9 300",
    taxCut: 326,
  },
  {
    bruto: "9 400",
    taxCut: 354,
  },
  {
    bruto: "9 500",
    taxCut: 383,
  },
  {
    bruto: "9 600",
    taxCut: 412,
  },
  {
    bruto: "9 700",
    taxCut: 441,
  },
  {
    bruto: "9 800",
    taxCut: 470,
  },
  {
    bruto: "9 900",
    taxCut: 499,
  },
  {
    bruto: "10 000",
    taxCut: 528,
  },
  {
    bruto: "10 100",
    taxCut: 556,
  },
  {
    bruto: "10 200",
    taxCut: 585,
  },
  {
    bruto: "10 300",
    taxCut: 614,
  },
  {
    bruto: "10 400",
    taxCut: 643,
  },
  {
    bruto: "10 500",
    taxCut: 672,
  },
  {
    bruto: "10 600",
    taxCut: 701,
  },
  {
    bruto: "10 700",
    taxCut: 730,
  },
  {
    bruto: "10 800",
    taxCut: 758,
  },
  {
    bruto: "10 900",
    taxCut: 787,
  },
  {
    bruto: "11 000",
    taxCut: 816,
  },
  {
    bruto: "11 100",
    taxCut: 845,
  },
  {
    bruto: "11 200",
    taxCut: 874,
  },
  {
    bruto: "11 300",
    taxCut: 903,
  },
  {
    bruto: "11 400",
    taxCut: 932,
  },
  {
    bruto: "11 500",
    taxCut: 960,
  },
  {
    bruto: "11 600",
    taxCut: 989,
  },
  {
    bruto: "11 700",
    taxCut: 1018,
  },
  {
    bruto: "11 800",
    taxCut: 1047,
  },
  {
    bruto: "11 900",
    taxCut: 1062,
  },
  {
    bruto: "12 000",
    taxCut: 1071,
  },
  {
    bruto: "12 100",
    taxCut: 1080,
  },
  {
    bruto: "12 200",
    taxCut: 1089,
  },
  {
    bruto: "12 300",
    taxCut: 1098,
  },
  {
    bruto: "12 400",
    taxCut: 1107,
  },
  {
    bruto: "12 500",
    taxCut: 1115,
  },
  {
    bruto: "12 600",
    taxCut: 1124,
  },
  {
    bruto: "12 700",
    taxCut: 1133,
  },
  {
    bruto: "12 800",
    taxCut: 1142,
  },
  {
    bruto: "12 900",
    taxCut: 1151,
  },
  {
    bruto: "13 000",
    taxCut: 1160,
  },
  {
    bruto: "13 100",
    taxCut: 1169,
  },
  {
    bruto: "13 200",
    taxCut: 1178,
  },
  {
    bruto: "13 300",
    taxCut: 1187,
  },
  {
    bruto: "13 400",
    taxCut: 1195,
  },
  {
    bruto: "13 500",
    taxCut: 1204,
  },
  {
    bruto: "13 600",
    taxCut: 1213,
  },
  {
    bruto: "13 700",
    taxCut: 1222,
  },
  {
    bruto: "13 800",
    taxCut: 1231,
  },
  {
    bruto: "13 900",
    taxCut: 1240,
  },
  {
    bruto: "14 000",
    taxCut: 1249,
  },
  {
    bruto: "14 100",
    taxCut: 1258,
  },
  {
    bruto: "14 200",
    taxCut: 1267,
  },
  {
    bruto: "14 300",
    taxCut: 1275,
  },
  {
    bruto: "14 400",
    taxCut: 1284,
  },
  {
    bruto: "14 500",
    taxCut: 1293,
  },
  {
    bruto: "14 600",
    taxCut: 1302,
  },
  {
    bruto: "14 700",
    taxCut: 1311,
  },
  {
    bruto: "14 800",
    taxCut: 1320,
  },
  {
    bruto: "14 900",
    taxCut: 1329,
  },
  {
    bruto: "15 000",
    taxCut: 1338,
  },
  {
    bruto: "15 100",
    taxCut: 1347,
  },
  {
    bruto: "15 200",
    taxCut: 1355,
  },
  {
    bruto: "15 300",
    taxCut: 1364,
  },
  {
    bruto: "15 400",
    taxCut: 1373,
  },
  {
    bruto: "15 500",
    taxCut: 1382,
  },
  {
    bruto: "15 600",
    taxCut: 1391,
  },
  {
    bruto: "15 700",
    taxCut: 1400,
  },
  {
    bruto: "15 800",
    taxCut: 1409,
  },
  {
    bruto: "15 900",
    taxCut: 1418,
  },
  {
    bruto: "16 000",
    taxCut: 1427,
  },
  {
    bruto: "16 100",
    taxCut: 1435,
  },
  {
    bruto: "16 200",
    taxCut: 1444,
  },
  {
    bruto: "16 300",
    taxCut: 1453,
  },
  {
    bruto: "16 400",
    taxCut: 1462,
  },
  {
    bruto: "16 500",
    taxCut: 1471,
  },
  {
    bruto: "16 600",
    taxCut: 1480,
  },
  {
    bruto: "16 700",
    taxCut: 1489,
  },
  {
    bruto: "16 800",
    taxCut: 1498,
  },
  {
    bruto: "16 900",
    taxCut: 1506,
  },
  {
    bruto: "17 000",
    taxCut: 1515,
  },
  {
    bruto: "17 100",
    taxCut: 1524,
  },
  {
    bruto: "17 200",
    taxCut: 1533,
  },
  {
    bruto: "17 300",
    taxCut: 1542,
  },
  {
    bruto: "17 400",
    taxCut: 1551,
  },
  {
    bruto: "17 500",
    taxCut: 1560,
  },
  {
    bruto: "17 600",
    taxCut: 1569,
  },
  {
    bruto: "17 700",
    taxCut: 1578,
  },
  {
    bruto: "17 800",
    taxCut: 1586,
  },
  {
    bruto: "17 900",
    taxCut: 1596,
  },
  {
    bruto: "18 000",
    taxCut: 1606,
  },
  {
    bruto: "18 100",
    taxCut: 1617,
  },
  {
    bruto: "18 200",
    taxCut: 1628,
  },
  {
    bruto: "18 300",
    taxCut: 1639,
  },
  {
    bruto: "18 400",
    taxCut: 1650,
  },
  {
    bruto: "18 500",
    taxCut: 1661,
  },
  {
    bruto: "18 600",
    taxCut: 1672,
  },
  {
    bruto: "18 700",
    taxCut: 1682,
  },
  {
    bruto: "18 800",
    taxCut: 1693,
  },
  {
    bruto: "18 900",
    taxCut: 1704,
  },
  {
    bruto: "19 000",
    taxCut: 1715,
  },
  {
    bruto: "19 100",
    taxCut: 1726,
  },
  {
    bruto: "19 200",
    taxCut: 1737,
  },
  {
    bruto: "19 300",
    taxCut: 1748,
  },
  {
    bruto: "19 400",
    taxCut: 1758,
  },
  {
    bruto: "19 500",
    taxCut: 1769,
  },
  {
    bruto: "19 600",
    taxCut: 1780,
  },
  {
    bruto: "19 700",
    taxCut: 1791,
  },
  {
    bruto: "19 800",
    taxCut: 1802,
  },
  {
    bruto: "19 900",
    taxCut: 1813,
  },
  {
    bruto: "20 000",
    taxCut: 1823,
  },
];
